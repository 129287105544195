import {
  withMembersArea,
  isMembersAreaInstalled,
  installMembersArea,
  registerMembersAreaApps,
  canInstallBlogWriterProfilesOnly,
} from '@wix/members-area-integration-kit';

export default {
  withMembersArea,
  isMembersAreaInstalled,
  installMembersArea,
  registerMembersAreaApps,
  canInstallBlogWriterProfilesOnly,
};
